<template>
    <v-container fluid>
        <v-form @submit.prevent="saveClient">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="12">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'client'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ $t('profile') }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="3" md="2" class="text-center">
                                <make-avatar v-model="photo" aspect-ratio="1"/>
                            </v-col>
                            <v-col cols="12" sm="9" md="10">
                                <v-row>
                                    <v-col class="py-0" cols="12" sm="9">
                                        <ValidationProvider ref="name" rules="min:3|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="name" type="text"
                                                          :error="!valid" :error-messages="errors"
                                                          :disabled="loading" prepend-icon="mdi-account"
                                                          :label="$t('client_name')" color="primary"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="3">
                                        <v-select v-model="languageUser" :items="listLanguageNames" :disabled="loading"
                                                  item-text="name" item-value="id" prepend-icon="mdi-google-translate"
                                                  :label="$t('language_interface')"></v-select>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-text-field v-model="phoneRaw" type="tel"
                                                      v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                      prepend-icon="mdi-phone" :label="$t('phone')"
                                                      color="primary" disabled readonly>
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="email" rules="email|min:5|max:50"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="email" type="email" :error-messages="errors"
                                                          :disabled="loading" :error="!valid"
                                                          prepend-icon="mdi-email" label="Email"
                                                          color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="12">
                                        <ValidationProvider ref="sex" rules="required"
                                                            v-slot="{ errors, valid }">
                                            <v-select v-model="sex" :disabled="loading"
                                                      :items="sexItems" :error-messages="errors"
                                                      prepend-icon="mdi-gender-male-female" :error="!valid"
                                                      color="primary" item-text="name" item-value="id"
                                                      :label="$t('sex')" clearable></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="deleted ? $t('client_deleted') : $t('client_remove')"
                                          @change="deleted ? active = false : active" color="red" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted"
                                          :label="$t('client_active')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mask} from 'vue-the-mask'
    import MakeAvatar from '../components/MakeAvatar.vue'
    import {mapGetters} from "vuex"
    import debounce from "lodash/debounce"

    export default {
        name: 'ClientProfile',
        directives: {
            mask,
        },
        components: {
            ValidationProvider,
            ValidationObserver,
            MakeAvatar,
        },
        inject: ['forceRerender'],
        data() {
            return {
                progress: 0,
                phoneRaw: null,
                name: null,
                email: null,
                active: false,
                deleted: false,
                languageUser: null,
                loading: false,
                loadingCities: false,
                photo: null,
                sex: null,
                sexItems: [],
            }
        },
        computed: {
            ...mapGetters(['listLanguages', 'listLanguageNames', 'lang', 'phoneMask']),
            phone: function () {
                return this.changePhone(this.phoneRaw)
            },
        },
        mounted() {
            this.getSexes()
            if (this.$route.params.id) {
                this.getClient(this.$route.params.id)
            }
        },
        methods: {
            async getSexes() {
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/sex`, {
                        params: params,
                    })
                    .then(res => {
                        this.sexItems = res.body.data
                    })
                    .catch(err => {
                        this.sexItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_sexes'))
                    });
            },
            async getClient(id) {
                var _this = this;
                this.progress = 0
                this.loading = true
                this.id = id
                await this.$http
                    .get(`admin/client/${id}`, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.name = res.body.data.name
                        this.phoneRaw = res.body.data.phone
                        this.email = res.body.data.email
                        this.photo = res.body.data.photo
                        this.sex = res.body.data.sex
                        this.active = res.body.data.active
                        this.deleted = res.body.data.deleted
                        this.languageUser = res.body.data.language ? res.body.data.language : this.lang
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_client'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveClient() {
                if (this.id) {
                    var _this = this;
                    this.progress = 0
                    this.loading = true
                    var formData = new FormData()
                    if (this.name) {
                        formData.append('name', this.name)
                    }
                    if (this.languageUser) {
                        formData.append('language', this.languageUser)
                    }
                    if (this.phone) {
                        formData.append('phone', this.phone)
                    }
                    if (this.email) {
                        formData.append('email', this.email)
                    }
                    if (this.sex) {
                        formData.append('sex', this.sex)
                    }
                    if (this.active) {
                        formData.append('active', 1)
                    }
                    if (this.deleted) {
                        formData.append('deleted', 1)
                    }
                    if (this.photo) {
                        if (this.photo.length > 250) {
                            var mimeType = this.getMimeType(this.photo)
                            var blob = this.dataURL64toBlob(this.photo)
                            if (mimeType && blob) {
                                formData.append('photo', blob, mimeType)
                            }
                        } else {
                            formData.append('photo', this.photo)
                        }
                    }

                    await this.$http
                        .put(`admin/client/${this.id}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            progress(e) {
                                if (e.lengthComputable) {
                                    _this.progress = Math.round((e.loaded / e.total) * 100)
                                }
                            }
                        })
                        .then(res => {
                            this.$toastr.success(this.$t('client_has_been_updated'))
                            //this.forceRerender()
                        })
                        .catch(err => {
                            this.$toastr.error(this.$t('client_has_not_been_updated'))
                            if (err && err.body && err.body.message) {
                                for (let prop in err.body.errors) {
                                    if (hasOwnProperty.call(err.body.errors, prop)) {
                                        if (_this.$refs[prop]) {
                                            _this.$refs[prop].setErrors([
                                                err.body.errors[prop][0]
                                            ])
                                        }
                                    }
                                }
                                if (!err.body.errors) {
                                    this.$toastr.error(err.body.message)
                                }
                            }
                        })
                        .finally(end => {
                            this.progress = 0
                            this.loading = false
                        })
                }
            }
        }
    }
</script>
